<template>
  <v-container style="max-height: 90vh; overflow-y: auto;">
    <Skeleton
      v-if="!isLoaded"
      type="list-item-avatar-three-line, image, article"
      :occurrence="1"
    />

    <v-row no-gutters v-else>
      <v-col cols="12">
        <v-row justify="space-between" no-gutters>
          <v-btn @click="showDialogMainEdit" icon class="mr-1">
            <v-icon color="primary">fas fa-edit</v-icon>
          </v-btn>

          <v-btn @click="logout" icon class="mr-1">
            <v-icon color="primary">fas fa-power-off</v-icon>
          </v-btn>
        </v-row>
      </v-col>

      <!-- Icon -->
      <v-col cols="6" class="offset-3 text-center mt-3">
        <v-icon size="70" v-if="$session.get('plan') === 'FREE'">fas fa-user</v-icon>
        <v-icon size="70" v-else>fas fa-user-crown</v-icon>
      </v-col>

      <!-- Firstname & name -->
      <v-col cols="12" class="text-center mt-3 font-weight-black">
        {{item.person.firstname | truncate}} {{item.person.name | truncate}}
      </v-col>

      <!-- Email -->
      <v-col cols="12" class="text-center">{{item.email}}</v-col>

      <!-- Description -->
      <v-col cols="12" md="10" offset-md="1" class="mt-6 text-center">
        <p class="font-weight-bold">{{$t('account.profile.subtitle')}}</p>

        <p v-if="$session.get('plan') === 'FREE'">{{$t('account.profile.free_text1')}}</p>
        <p v-else>{{$t('account.profile.premium_text1')}}</p>

        <p v-if="$session.get('plan') === 'FREE'">{{$t('account.profile.free_text2')}}</p>
        <p v-else>{{$t('account.profile.premium_text2')}}</p>

      </v-col>

      <!-- Contact us -->
      <v-col v-if="$session.get('plan') !== 'FREE'" cols="12" class="text-center">
        <v-btn
          href="mailto:support@roote.io"
          large
          text
          color="primary">
          <span style="font-size: 12px !important;">
            {{$t('account.profile.buttons.contact')}}
          </span>
        </v-btn>
      </v-col>

      <!-- Button subscribe -->
      <v-col cols="12" v-if="$session.get('plan') === 'FREE'" class="text-center mb-2">
        <v-btn
          @click="showDialogSubscribe"
          large
          color="primary"
          width="250"
          style="border-radius: 10px !important;"
          class="font-weight-bold">
          {{$t('account.profile.subscription.buttons.upgrade')}}
        </v-btn>
      </v-col>

      <v-col cols="12" class="mt-4">
        <v-divider class="mx-4"/>
      </v-col>

      <!-- Subscription part -->
      <v-col cols="12" md="10" class="mt-4 offset-md-1">
        <v-row no-gutters>
          <v-col cols="12">
            <h3>{{$t('account.profile.parts.subscription')}}</h3>
          </v-col>

          <!-- Subscription -> Free -->
          <v-col cols="12" v-if="$session.get('plan') === 'FREE'">
            <p>{{$t('account.profile.subscription.free-plan')}}</p>
          </v-col>

          <!-- Subscription -> Premium  -->
          <v-col cols="12" v-else>
            <p>{{$t('account.profile.subscription.premium-plan', {date: $moment(item.subscription.dates.start).format("DD/MM/YYYY")})}}</p>
            <p>{{$t('account.profile.subscription.next-occurrence', {date: $moment(item.subscription.dates.next_occurrence).format("DD/MM/YYYY")})}}</p>

            <v-row no-gutters justify="center">
              <v-btn
                @click="showDialogUnsubscribe"
                color="white"
                class="font-weight-bold"
                style="color: #E53935 !important; border-radius: 10px !important;">
                {{$t('account.profile.subscription.buttons.cancel')}}
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-col>

      <!-- Invoices -->
      <v-col cols="12" md="10" offset-md="1" class="mt-4">
        <v-row no-gutters>
          <v-col cols="12">
            <h3>{{$t('account.profile.parts.invoices')}}</h3>
          </v-col>
          <v-col cols="12" v-if="!isLoadedOrders">
            <Skeleton
              type="list-item"
              :occurrence="3"/>
          </v-col>

          <v-col cols="12" v-else>
            <v-data-table
              v-if="orders && orders.length > 0"
              :headers="headersInvoices"
              :items="orders"
              :items-per-page="5"
              class="elevation-1"
            >
              <template v-slot:item="{item}">
                <tr>
                  <td>{{ $moment(item.date_order).format("DD/MM/YYYY") }}</td>
                  <td>{{ item.amount.amount_incl_tax }}€</td>
                  <td class="text-center">
                    <v-btn icon @click="downloadInvoice(item._id)" :loading="loadingOrderID === item._id">
                      <v-icon small>far fa-download</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <p v-else>{{$t('account.profile.invoices.no-result')}}</p>
          </v-col>
        </v-row>
      </v-col>

      <!-- Security -->
      <v-col cols="12" md="10" offset-md="1" class="mt-4">
        <v-row no-gutters>
          <v-col cols="12">
            <h3>{{$t('account.profile.parts.security')}}</h3>
          </v-col>

          <v-col cols="12" v-if="item.last_login">{{$t('account.profile.last_login', {date: $moment(item.last_login).format("DD/MM/YYYY")})}}</v-col>
          <v-col cols="12" v-if="item.recovery.last_password_modification">{{$t('account.profile.last_password_modification', {date: $moment(item.recovery.last_password_modification).format("DD/MM/YYYY")})}}</v-col>

          <v-col cols="12" class="text-start my-2">
            <v-btn
              width="170"
              @click="logout"
              color="white"
              class="font-weight-bold"
              elevation="3"
              style="color: #4D59FF !important;border-radius: 10px !important;padding: 0 !important;"
            >
              {{$t('account.profile.buttons.logout')}}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" class="mt-4">
        <v-divider class="mx-4" />
      </v-col>

      <!-- Danger zone -->
      <v-col cols="12" md="10" offset-md="1" class="mt-4">
        <v-row no-gutters align="center">

          <v-col cols="12" class="mb-2">
            <h3>{{$t('account.profile.parts.danger_zone')}} ⚠️</h3>
          </v-col>

          <!-- Password edit -->
          <v-col cols="12" md="6" class="text-left">
            <v-btn
              width="170"
              @click="showDialogPasswordEdit"
              color="white"
              class="font-weight-bold"
              elevation="3"
              style="color: #4D59FF !important;border-radius: 10px !important;padding: 0 !important;"
            >
              {{$t('account.profile.buttons.change_password')}}
            </v-btn>
          </v-col>

          <!-- Delete btn -->
          <v-col cols="12" md="6" class="text-center">
            <v-btn
              width="170"
              @click="showDialogDelete"
              color="white"
              class="font-weight-bold"
              elevation="3"
              style="color: #E53935 !important;border-radius: 10px !important;padding: 0 !important;">
              {{$t('account.profile.buttons.delete')}}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Main dialog -->
    <ProfileChangeMainDialog
      v-if="showDialog.changeMain"
      :value="showDialog.changeMain"
      :item="item"
      @close="(needRefresh) => closeDialogMainEdit(needRefresh)" />

    <!-- Password dialog -->
    <ProfileChangePasswordDialog
      v-if="showDialog.changePassword"
      :value="showDialog.changePassword"
      @close="(needRefresh) => closeDialogPasswordEdit(needRefresh)" />

    <!-- Subscribe dialog -->
    <ProfileSubscribeDialog
      v-if="showDialog.subscribe"
      :value="showDialog.subscribe"
      :item="item"
      @close="(needRefresh) => closeDialogSubscribe(needRefresh)" />

    <!-- Unsubscribe dialog -->
    <ProfileUnsubscribeDialog
      v-if="showDialog.unsubscribe"
      :value="showDialog.unsubscribe"
      :item="item"
      @close="(needRefresh) => closeDialogUnsubscribe(needRefresh)" />

    <!-- Delete dialog -->
    <ProfileDeleteDialog
      v-if="showDialog.delete"
      :value="showDialog.delete"
      @close="(needRefresh) => closeDialogDelete(needRefresh)" />

  </v-container>
</template>

<script>

export default {

  name: "Profile",

  components: {
    ProfileDeleteDialog: () => import('@/components/Account/Dialogs/DeleteDialog'),
    ProfileSubscribeDialog: () => import('@/components/Account/Dialogs/SubscribeDialog'),
    ProfileChangeMainDialog: () => import('@/components/Account/Dialogs/ChangeMainDialog'),
    ProfileChangePasswordDialog: () => import('@/components/Account/Dialogs/ChangePasswordDialog'),
    Skeleton: () => import("@/components/Common/Skeleton"),
    ProfileUnsubscribeDialog: () => import("@/components/Account/Dialogs/UnsubscribeDialog")
  },

  data() {
    return {
      item: null,
      orders: [],

      isLoaded: false,
      isLoadedOrders: false,
      loadingOrderID: null,

      showDialog: {
        changeMain: false,
        changePassword: false,
        subscribe: false,
        delete: false,
        unsubscribe: false
      },

      headersInvoices: [
        {
          text: this.$t('account.profile.invoices.headers.date'),
          value: 'date_order',
          sortable: false,
        },
        {
          text: this.$t('account.profile.invoices.headers.amount'),
          value: 'amount.amount_incl_tax',
          sortable: false,
        },
        {
          text: this.$t('account.profile.invoices.headers.download'),
          sortable: false
        }
      ]
    }
  },

  created() {
    this.getData();
    this.getOrders();
    this.$store.commit("pageHead/setTitle", this.$t('account.profile.pageTitle'))
  },

  methods: {

    showDialogMainEdit() {
      this.showDialog.changeMain = true;
    },

    closeDialogMainEdit(reloadData = false) {
      if (reloadData)
        this.getData();
      this.showDialog.changeMain = false;
    },

    showDialogPasswordEdit() {
      this.showDialog.changePassword = true;
    },
    closeDialogPasswordEdit(reloadData = false) {

      this.showDialog.changePassword = false;
      if (reloadData) {
        this.$store.commit("toast/showSuccess", this.$t('account.profile.passwordDialog.success'));
        this.getData();
      }
    },

    showDialogDelete() {
      this.showDialog.delete = true;
    },
    closeDialogDelete(reloadData = false) {
      this.showDialog.delete = false;
      if (reloadData) {
        this.$store.dispatch("logout").then(() => {
          this.$router.push({name: "Home"});
        });

      }
    },

    showDialogSubscribe(){
      this.showDialog.subscribe = true;
    },
    closeDialogSubscribe(reloadData = false) {
      this.showDialog.subscribe = false;
      if (reloadData)
        this.getData();
    },

    showDialogUnsubscribe() {
      this.showDialog.unsubscribe = true;
    },
    closeDialogUnsubscribe(reloadData = false) {
      this.showDialog.unsubscribe = false;
      if (reloadData)
        this.getData();
    },

    getData() {

      this.isLoaded = false;
      this.$http
      .get(`/map/users/${this.$session.get('id')}`, {
        headers: {
          Authorization: `Bearer ${this.$session.get('jwt')}`
        }
      })
      .then((res) => {
        this.item = res.data;
        this.$session.set('person.firstname', res.data.person.firstname);
        this.$session.set('person.name', res.data.person.name);
        this.$session.set("plan", res.data.subscription.plan);

        this.$i18n.locale = res.data.preferences.lang;
        this.$session.set("lang", this.$i18n.locale);
        this.$vuetify.lang.current = this.$i18n.locale;
        this.$moment.locale(this.$i18n.locale);
      })
      .catch(err => {
        this.$store.commit("alert/showErrorAxios", err);
      })
      .finally(() => {
        this.isLoaded = true;
      })
    },

    getOrders() {

      this.isLoadedOrders = false;
      this.$http
      .get(`/map/users/${this.$session.get('id')}/orders`, {
        headers: {
          Authorization: `Bearer ${this.$session.get('jwt')}`
        }
      })
      .then((res) => {
        this.orders = res.data.items;
      })
      .catch(err => {
        this.$store.commit("alert/showErrorAxios", err);
      })
      .finally(() => {
        this.isLoadedOrders = true;
      })
    },

    downloadInvoice(orderID) {
      this.loadingOrderID = orderID;

      this.$http.get(`/map/users/${this.$session.get('id')}/orders/${orderID}/invoice`, {
          headers:
            {
              Authorization: "Bearer " + this.$session.get('jwt')
            },
          responseType: 'arraybuffer',
        })
      .then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'invoice.pdf');
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch(err => {
        this.$store.commit("toast/showError", this.$t('common.errors.500'));
      })
      .finally(() => {
        this.loadingOrderID = null;
      })
    },

    logout() {
      this.$store.dispatch("logout");
      this.state = 0;
      if (this.$route.name !== "Home")
        this.$router.push({name: "Home"});
      else
        this.$router.go();

    },
  }
}
</script>

<style scoped>

</style>